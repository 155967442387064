<template>
  <div class="view__container">
    <div class="content__top">
      <div>
        <Breadcrumbs
          :views="[]"
          :currentView="{ label: 'Gestión de documentos', icon: 'folder-open' }"
        />
        <h2>Correos</h2>
      </div>
      <div class="content__buttons">
        <Button type="button" variant="secondary" size="xsmall" @click="'';">
          <unicon
            width="17px"
            height="17px"
            name="info-circle"
            fill="var(--main-color-500)"
          ></unicon>
        </Button>
        <Button type="button" variant="secondary" size="small" @click="openExportModal">
          <unicon
            width="16px"
            height="16px"
            name="file-upload"
            fill="var(--main-color-500)"
          ></unicon>
          Exportar
        </Button>
      </div>
    </div>

    <Tabs
      :tabList="[
        { title: 'Envios', icon: 'table' },
        { title: 'Template', icon: 'apps' },
      ]"
      @tabChange="onTabChange"
    >
      <template v-slot:tabPanel-1>
        <div class="content">
          <table>
            <tbody>
              <tr>
                <th>#</th>
                <th>Documento</th>
                <th v-for="event in events" :key="event">
                  {{ event.charAt(0).toUpperCase() + event.slice(1) }}
                </th>
                <th></th>
              </tr>
              <template v-if="emails && !isLoading">
                <tr v-for="(email, index) in emails" :key="email.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{
                      files.find(({ metadata }) => metadata.customMetadata.id === email.id).metadata
                        .name
                    }}
                  </td>
                  <td v-for="event in events" :key="event">{{ email[event] || 0 }}</td>
                  <td class="redirect">
                    <router-link
                      :to="{
                        name: 'Addresses',
                        params: { id: email.id },
                      }"
                      type="button"
                    >
                      Ver más...
                    </router-link>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <empty-state v-if="!isLoading && !emails.length" label="correos" :haveAction="false" />
          <div v-if="isLoading" class="loading"><loading-spinner /></div>
        </div>
      </template>
      <template v-slot:tabPanel-2>
        <div class="template__content">
          <tiptap-editor v-model="content" @save="onSave" />
        </div>
        <div class="template__content">
          <p>Firma:</p>
          <div class="section">
            <figure class="section__logo">
              <div v-if="isSignLoading" class="loader__container"><loading-spinner /></div>
              <img v-else-if="fileURL" :src="fileURL" alt="" />
              <div v-else>
                <img src="@/assets/imgPlaceholder.svg" alt="" />
              </div>
            </figure>

            <div class="section__buttons section__buttons--logo">
              <input
                @input="(e) => handleInputFile(e.target.files[0])"
                type="file"
                id="actual-btn"
                hidden
              />

              <Button size="small" variant="secondary">
                {{ isImageUploading ? 'Subiendo...' : 'Subir imágen' }}
                <label for="actual-btn"
                  >{{ isImageUploading ? 'Subiendo...' : 'Subir imágen' }}
                </label>
              </Button>
            </div>
          </div>
        </div>
      </template>
    </Tabs>

    <export-modal
      ref="modal__export"
      @export="downloadExcel"
      :formats="{
        excel: {
          id: 'excel',
          name: 'Excel',
          options: [
            { id: 'mails', name: 'Correos' },
          ],
        },
      }"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import TiptapEditor from '@/components/TiptapEditor.vue';
import Button from '@/components/buttons/Button.vue';
import Tabs from '@/components/Tabs.vue';
import LoadingSpinner from '@/components/loading/LoadingSpinner.vue';
import EmptyState from '@/components/EmptyState.vue';
import ExportModal from '@/components/ExportModal.vue';
import * as XLSX from 'xlsx';

export default {
  name: 'Emails',
  components: {
    Breadcrumbs,
    TiptapEditor,
    Tabs,
    Button,
    LoadingSpinner,
    EmptyState,
    ExportModal,
  },
  data() {
    return {
      content: '',
      isImageUploading: false,
      fileURL: '',
      isLoading: false,
      isSignLoading: false,
      events: ['processed', 'delivered', 'open', 'bounce', 'dropped', 'spamreport', 'deferred'],
    };
  },
  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions(['updateCompany', 'uploadSignature', 'getSignature']),
    ...mapActions('emails', ['fetchEmails']),
    ...mapActions('files', ['fetchFiles']),
    async onSave() {
      await this.updateCompany({ ...this.company, emailTemplate: this.content });
    },

    onTabChange() {},

    async setSignatureURL() {
      try {
        this.isSignLoading = true;
        const file = await this.getSignature();
        const url = await file.getDownloadURL();
        this.fileURL = url;
        this.isSignLoading = false;
      } catch (e) {
        console.log(e);
        this.isSignLoading = false;
      }
    },

    async handleInputFile(file) {
      if (!file) return;
      try {
        this.isImageUploading = true;
        await this.uploadSignature(file);
        this.setAlert({
          state: 'success',
          message: 'Logo subido',
        });
        this.setSignatureURL();
        this.isImageUploading = false;
      } catch (e) {
        console.log(e);
        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error, por favor inténtelo nuevamente',
        });
      }
    },

    openExportModal() {
      this.$refs.modal__export.open();
    },

    downloadExcel() {
      const mails = this.emails.map((mail) => {
        const mapedMail = {};
        mapedMail.Documento = mail.name;
        this.events.forEach((event) => {
          mapedMail[event] = mail[event] || 0;
        });
        return mapedMail;
      });
      console.log(mails);
      const worksheet = XLSX.utils.json_to_sheet(mails);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Correos');
      XLSX.writeFile(workbook, 'reporte.xlsx');
    },
  },

  computed: {
    ...mapState({
      company: (state) => state.company,
      emails(state) {
        const emails = [];
        const eventsName = this.events.reduce((acc, curr) => {
          acc[curr] = 0;
          return acc;
        }, {});
        state.emails.emails.forEach((emailEl) => {
          const email = { ...emailEl, ...eventsName };
          const addresses = [];
          email.addresses.forEach((addressEl) => {
            const address = { ...addressEl, ...eventsName };
            const events = [];
            address.events.forEach((eventEl) => {
              const event = { ...eventEl };
              email[eventEl.event] += 1;
              address[eventEl.event] += 1;
              events.push(event);
            });
            address.events = events;
            addresses.push(address);
          });
          email.addresses = addresses;
          emails.push(email);
        });
        return emails;
      },
      files: (state) => state.files.files.map((file) => ({
        ...file,
        metadata: {
          ...file.metadata,
          name: file.metadata.customMetadata.name,
        },
      })),
    }),
  },

  async mounted() {
    try {
      this.isLoading = true;
      if (this.emails.length === 0) await this.fetchEmails();
      if (this.files.length === 0) await this.fetchFiles();
      await this.setSignatureURL();
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.isLoading = false;
    }
    if (!this.company.emailTemplate) {
      this.content = '<h1 class="releases-heading"><strong>Esto es un template de correo</strong></h1><p class="releases-paragraph">Dar <u>click</u> para editar el correo y el icono de guardar antes de cerrarlo.</p><p class="releases-paragraph"><em>elha - plataforma digital.</em></p>';
    } else {
      this.content = this.company.emailTemplate;
    }
  },
};
</script>

<style lang="scss" scoped>
.view__container {
  border-radius: 10px;
  background-color: white;
  overflow: hidden;

  .content {
    overflow: auto;
  }

  .content__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
  }

  .content__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }

  p {
    font-weight: var(--medium);
    color: var(--font-color-500);
    margin-bottom: 0.5rem;
  }

  .template__content {
    margin-bottom: 1rem;
  }

  label {
    cursor: pointer;
    /* Style as you please, it will become the visible UI component. */
  }

  table {
    width: 100%;
  }
}

.section {
  margin-right: 1rem;
}

.section__logo {
  img {
    background-color: var(--gray-color-100);
    border-radius: 8px;
    width: 220px;
    height: 75px;
    padding: 1.5rem;
    object-fit: contain;
  }
  .loader__container {
    background-color: var(--gray-color-100);
    border-radius: 8px;
    width: 250px;
    height: 150px;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.section__buttons {
  margin: 1rem 0;
  display: flex;
  gap: 0.5rem;
  width: fit-content;
  position: relative;
  &--logo {
    cursor: pointer;
    button {
      min-width: 7rem;
    }
    label {
      cursor: pointer;
      height: 100%;
      position: absolute;
      white-space: nowrap;
      display: flex;
      align-items: center;
    }
  }
}

.redirect a {
  cursor: pointer;
  color: var(--main-color-500);
  fill: var(--main-color-500);
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: underline var(--main-color-500);
  }
}

.loading {
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
