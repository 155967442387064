var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view__container"},[_c('div',{staticClass:"content__top"},[_c('div',[_c('Breadcrumbs',{attrs:{"views":[],"currentView":{ label: 'Gestión de documentos', icon: 'folder-open' }}}),_c('h2',[_vm._v("Correos")])],1),_c('div',{staticClass:"content__buttons"},[_c('Button',{attrs:{"type":"button","variant":"secondary","size":"xsmall"},on:{"click":function($event){'';}}},[_c('unicon',{attrs:{"width":"17px","height":"17px","name":"info-circle","fill":"var(--main-color-500)"}})],1),_c('Button',{attrs:{"type":"button","variant":"secondary","size":"small"},on:{"click":_vm.openExportModal}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"file-upload","fill":"var(--main-color-500)"}}),_vm._v(" Exportar ")],1)],1)]),_c('Tabs',{attrs:{"tabList":[
      { title: 'Envios', icon: 'table' },
      { title: 'Template', icon: 'apps' } ]},on:{"tabChange":_vm.onTabChange},scopedSlots:_vm._u([{key:"tabPanel-1",fn:function(){return [_c('div',{staticClass:"content"},[_c('table',[_c('tbody',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Documento")]),_vm._l((_vm.events),function(event){return _c('th',{key:event},[_vm._v(" "+_vm._s(event.charAt(0).toUpperCase() + event.slice(1))+" ")])}),_c('th')],2),(_vm.emails && !_vm.isLoading)?_vm._l((_vm.emails),function(email,index){return _c('tr',{key:email.id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(" "+_vm._s(_vm.files.find(function (ref) {
        var metadata = ref.metadata;

        return metadata.customMetadata.id === email.id;
    }).metadata .name)+" ")]),_vm._l((_vm.events),function(event){return _c('td',{key:event},[_vm._v(_vm._s(email[event] || 0))])}),_c('td',{staticClass:"redirect"},[_c('router-link',{attrs:{"to":{
                      name: 'Addresses',
                      params: { id: email.id },
                    },"type":"button"}},[_vm._v(" Ver más... ")])],1)],2)}):_vm._e()],2)]),(!_vm.isLoading && !_vm.emails.length)?_c('empty-state',{attrs:{"label":"correos","haveAction":false}}):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"loading"},[_c('loading-spinner')],1):_vm._e()],1)]},proxy:true},{key:"tabPanel-2",fn:function(){return [_c('div',{staticClass:"template__content"},[_c('tiptap-editor',{on:{"save":_vm.onSave},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),_c('div',{staticClass:"template__content"},[_c('p',[_vm._v("Firma:")]),_c('div',{staticClass:"section"},[_c('figure',{staticClass:"section__logo"},[(_vm.isSignLoading)?_c('div',{staticClass:"loader__container"},[_c('loading-spinner')],1):(_vm.fileURL)?_c('img',{attrs:{"src":_vm.fileURL,"alt":""}}):_c('div',[_c('img',{attrs:{"src":require("@/assets/imgPlaceholder.svg"),"alt":""}})])]),_c('div',{staticClass:"section__buttons section__buttons--logo"},[_c('input',{attrs:{"type":"file","id":"actual-btn","hidden":""},on:{"input":function (e) { return _vm.handleInputFile(e.target.files[0]); }}}),_c('Button',{attrs:{"size":"small","variant":"secondary"}},[_vm._v(" "+_vm._s(_vm.isImageUploading ? 'Subiendo...' : 'Subir imágen')+" "),_c('label',{attrs:{"for":"actual-btn"}},[_vm._v(_vm._s(_vm.isImageUploading ? 'Subiendo...' : 'Subir imágen')+" ")])])],1)])])]},proxy:true}])}),_c('export-modal',{ref:"modal__export",attrs:{"formats":{
      excel: {
        id: 'excel',
        name: 'Excel',
        options: [
          { id: 'mails', name: 'Correos' } ],
      },
    }},on:{"export":_vm.downloadExcel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }